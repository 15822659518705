<template>
  <h1>404</h1>
</template>

<script>
export default {
  created () {
    document.title = "Not found"
  }
}
</script>